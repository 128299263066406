import Partner from './enums/Partner';

export const baseUrl = 'https://api.staging.match-flix.de'; // development
export const partner: Partner = Partner.LIENERT;

export const isLienert = (partner as Partner) === Partner.LIENERT; // dont change!!!
export const isDevelopment = process.env.NODE_ENV === 'development'; // dont change!!!

export const loginURL = `${baseUrl}/login`;
export const get2FASecretURL = `${baseUrl}/get2FASecret`;
export const verifyTanURL = `${baseUrl}/verifyTan`;
export const logoutURL = `${baseUrl}/logout`;
export const verifyTokenURL = `${baseUrl}/user`;

export const registerURL = `${baseUrl}/register`;

export const candidatePlaceholderPictureURL = '/avatar.png';
